
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Machine } from '@/types/machineryManage'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile }
})

export default class ScheduleAdd extends Vue {
  typeList = []
  maintenance = []
  userList = []
  strate = []
  private info: Machine = {
    // 所属项目
    projectId: '',
    // 机械管家
    userId: '',
    // 机械名称
    machineryName: '',
    // 机械分类字典
    machineryType: '',
    // 机身编号
    machineryCode: '',
    // 品牌名称
    machineryBrand: '',
    // 机械型号
    machineryModel: '',
    // 购入日期
    purchaseDate: '',
    // 关联策略
    strategyIdList: [],
    // 关联策略
    fileList: []
  }

  private rules = {
    machineryName: [
      { required: true, message: '请输入机械名称', trigger: ['blur', 'change'] }
    ],
    machineryCode: [
      { required: true, message: '请输入机械编号', trigger: ['blur', 'change'] }
    ],
    machineryType: [
      { required: true, message: '请选择机械类型', trigger: ['blur', 'change'] }
    ],
    machineryBrand: [
      { required: true, message: '请输入品牌名称', trigger: ['blur', 'change'] }
    ],
    projectId: [
      { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }
    ],
    machineryModel: [
      { required: true, message: '请输入机械型号', trigger: ['blur', 'change'] }
    ],
    userId: [
      { required: true, message: '请选择机械管家', trigger: ['blur', 'change'] }
    ]
  }

  private pickerOptions = {}

  get projectList () {
    return this.$store.state.normalProjectList
  }

  get machineryId () {
    return this.$route.query.machineryId as string
  }

  created () {
    this.getTypeList()
    this.getConfigList()
  }

  // 类型
  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      this.typeList = res.machineryType || []
    })
  }

  getConfigList () {
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryStrategyByPage).then(res => {
      if (res) {
        this.maintenance = res.list || []
        this.machineryId && this.getDetail()
      }
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByMachineryId, { machineryId: this.machineryId }).then(res => {
      if (res) {
        this.info = res || {}
        this.changeStrate(this.info.strategyIdList)
        this.changeProject()
      }
    })
  }

  changeProject () {
    this.info.projectId && this.getUserList(this.info.projectId)
  }

  changeStrate (val: any) {
    this.strate = []
    let arr: any = []
    val.forEach((item: any) => {
      const list = this.maintenance.filter((item1: any) => {
        return item === item1.strategyId
      })
      arr = arr.concat(list)
    })
    this.strate = arr
    console.log()
  }

  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      this.userList = res || []
    })
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        let flag = false
        this.info.strategyIdList.forEach((item: any) => {
          flag = this.maintenance.some((item1: any) => {
            return item === item1.strategyId && item1.strategyState === '2'
          })
        })
        if (flag) {
          this.$message.error('保养策略中不能选择被禁用的策略！')
          return false
        }
        this.$axios.post(this.machineryId ? this.$apis.machineryManage.updateYhMachineryInfo : this.$apis.machineryManage.insertYhMachineryInfo, {
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    ;(this.info as Machine).fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    this.submit(() => {
      this.$router.back()
    })
  }
}
